.main-room-container {
    height: 100%;
    /* background-color: orange; */
    background-color: #1a1a1d;
    display: flex;
    overflow-x: hidden;
}

.gameplay-container {
    flex-grow: 1;
    box-sizing: content-box;
}


.chat-container {
    width: 20%;
    height: 100vh;
}

.chat-hidden {
    width: 0%;
    -webkit-transition: width 0.5s linear;
       -moz-transition: width 0.5s linear;
        -ms-transition: width 0.5s linear;
         -o-transition: width 0.5s linear;
            transition: width 0.5s linear;
}

.chat-open {
     width: 20%;
     -webkit-transition: width 0.5s linear;
        -moz-transition: width 0.5s linear;
         -ms-transition: width 0.5s linear;
          -o-transition: width 0.5s linear;
             transition: width 0.5s linear;
}
