/* #mainNavbar {
    height: px;
} */

#whiteText {
    color: white;
}

.user-nav-info:hover {
    cursor: pointer;
}

