.chatHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #808080;
    border-radius: 4px 4px 0 0;
    /* height: 8vh; */
    width: 100%;
}


.leftInnerContainer {
    flex: 0.5;
    display: flex;
    align-items: center;
    margin-left: 5%;
    color: white;
}

.rightInnerContainer {
    display: flex;
    flex: 0.5;
    justify-content: flex-end;
    margin-right: 5%;
    /* color: white; */
}

#dropdownToggleIcon {
    color: white;
    background-color: transparent;
    border: none;
}


#dropdownToggleIcon:hover {
    color: rgba(0, 0, 0, .6);
    cursor: pointer;
}


#usersDropdownItem {
    color: black;
}
