#mainChatContainer {
    border-left: 1px solid lightgrey;
    /* position: relative; */
    height: 100%;
    padding: 0;
    display: flex;
    /* flex-direction: column; */
    flex-flow: column;
    background-color: white;
    /* background: #1a1a1d; */
}
