
.home-page-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
}


.join-main-container {
    background-color: #1a1a1d;
    /* height: 100vh; */
    /* min-height: 100%; */
    /* height: 100%; */
    /* Fallback for browsers do NOT support vh unit */
    /* min-height: 100vh; */
    padding: 10%;
    flex-grow: 1;
}

.welcome-text {
    color: white;
}

.join-button-row {
    margin-top: 20px;
}

